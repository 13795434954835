import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap'
import { FaHome } from 'react-icons/fa'
import { FaSquarePhone } from 'react-icons/fa6'
import { GoChecklist } from "react-icons/go";
import { IoDocumentTextOutline } from 'react-icons/io5'
import { MdPeople } from 'react-icons/md'
import { IoInformationCircleOutline, IoDocumentAttachOutline  } from "react-icons/io5";
import { CgProfile } from 'react-icons/cg'
import { FaPeopleRoof  } from 'react-icons/fa6'
import { LuDoorOpen } from "react-icons/lu";
import { VscBook } from "react-icons/vsc";
import {  Link } from 'react-router-dom';
import { BsJournalBookmark } from "react-icons/bs";
import { GrNotes } from "react-icons/gr";
import styles from './SideMenu.module.scss'

const SideMenu = () => {

  const [menuList, setMenuList] = useState([])
  const menuSuperAdmin = [{
    title: "Dashboard",
    icon: <FaHome />,
    type:"menu",
    link:"../dashboard"
  },{
    title: "Manage admin",
    icon: <CgProfile/>,
    type:"menu",
    link:"./adminManagement"
  },{
    title: "Manage Afiliator",
    icon: <FaPeopleRoof />,
    type:"menu",
    link:"./afiliatorManagement"
  },{
    title: "Property Detail",
    icon: <LuDoorOpen />,
    type:"menu",
    link:"./property"
  },{
    title: "Reservation",
    icon: <BsJournalBookmark />,
    type:"menu",
    link:"./reservation"
  },{
    title: "Reporting",
    icon: <IoDocumentAttachOutline />,
    type:"menu",
    link:"./reporting"
  },{
  // },{
  //   title: "Terms and Condition",
  //   icon: <GrNotes/>,
  //   type:"menu",
  //   link:"./termsAndCondition"
  // },{
  //   title: "Privacy Policy",
  //   icon: <GoChecklist/>,
  //   type:"menu",
  //   link:"./privacyPolicy"
    title: "About HMS",
    icon: <IoInformationCircleOutline  />,
    type:"menu",
    link:"./aboutHms"
  },{
    title: "Basic Knowledge",
    icon: <VscBook  />,
    type:"menu",
    link:"./basicKnowledge"
  },{
    title: "Contact Us",
    icon: <FaSquarePhone />,
    type:"menu",
    link:"./contactUs"
  }
  ] 
  
  const menuAdminHotel = [{
    title: "Dashbord",
    icon: <FaHome />,
    type:"menu",
    link:"../dashboard"
  },{
    title: "Property Detail",
    icon: <LuDoorOpen />,
    type:"menu",
    link:"./property"
  },{
    title: "Reservation",
    icon: <BsJournalBookmark />,
    type:"menu",
    link:"./reservation"
  },{
    title: "Reporting",
    icon: <IoDocumentAttachOutline />,
    type:"menu",
    link:"./reporting"
  //   title: "Terms and Condition",
  //   icon: <GrNotes/>,
  //   type:"menu",
  //   link:"./termsAndCondition"
  // },{
  //   title: "Privacy Policy",
  //   icon: <GoChecklist/>,
  //   type:"menu",
  //   link:"./privacyPolicy"
  // },{
  //   title: "About HMS",
  //   icon: <IoInformationCircleOutline  />,
  //   type:"menu",
  //   link:"./aboutHms"
  // },{
  //   title: "Basic Knowledge",
  //   icon: <VscBook  />,
  //   type:"menu",
  //   link:"./basicKnowledge"
  // },{
  //   title: "Contact Us",
  //   icon: <FaSquarePhone />,
  //   type:"menu",
  //   link:"./contactUs"
  }]
  
  const menuAfiliator = [{
    title: "Dashbord",
    icon: <FaHome />,
    type:"menu",
    link:"../dashboard"
  },{
    title: "Reservation",
    icon: <BsJournalBookmark />,
    type:"menu",
    link:"./reservation"
  }]
  
  const selectMenu = (index) => {
    localStorage.setItem("activeMenu", index)
  }

  useEffect(()=>{
    if(localStorage.getItem("activeMenu")){
      localStorage.setItem("activeMenu", localStorage.getItem("activeMenu"))
    }else{
      localStorage.setItem("activeMenu", 0)
    }

    if( localStorage.getItem('role') === 'SUPER_ADMIN' ){
      setMenuList(menuSuperAdmin)
    }else if( localStorage.getItem('role') === 'AFFILIATOR' ){
      setMenuList(menuAfiliator)
    }else{
      setMenuList(menuAdminHotel)
    }
  },[localStorage.getItem("is_super_admin"), ])

	return (
    <Container className={styles.container}>
      { menuList.length > 0 && <>
        <Row>
          <Col className={styles.logo}>
            <a href="/">
              <img alt={"images"} height={"100%"} width={"100%"} src={"/images/logoMain.png"}/>
            </a>
          </Col>
        </Row>
        {
          menuList.map((data, index)=>(
            data.type === "menu" ? 
              <Row key={index} onClick={ ()=>selectMenu(index) }>
                <Link to={data.link} className={styles.link}>
                  <Col xs="12" className={Number(localStorage.getItem("activeMenu")) === index ? styles.list_menu_active : styles.list_menu}>
                    <p> {data.icon} &nbsp;&nbsp; {data.title} </p>
                  </Col>
                </Link>
              </Row>
            :
              <Row key={index}>
                <Col xs="12" className={styles.list_menu_title}>
                  <p> {data.title} </p>
                </Col>
              </Row>
          ))
        }
        </>
      }
    </Container>
	);
};

export default SideMenu;
