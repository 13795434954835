import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table  } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";
import styles from './Dashboard.module.scss';
import { toRupiah } from 'to-rupiah';
import { connect } from "react-redux";
import { setDashboardOverviewPerHotel, setDashboardRegionOverview, setDashboardStatisticPerHotel, setDashboardSourceOverview } from '../../store/actions/dashboardAction'
import { setAllHotel } from '../../store/actions/hotelAction'
import Chart from 'chart.js/auto';

const Dashboard = ({
  dispatch,
  dataDashboard,
  dataHotel,
}) => {

  const [selectedHotel, setSelectedHotel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAnalyticReady, setIsAnalyticReady] = useState(false);
  const [allHotelAvail, setAllHotelAvail] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [regionOverview, setRegionOverview] = useState(null);
  const [overviewHotel, setOverviewHotel] = useState([]);
  const [statisticHotel, setStatisticHotel] = useState([]);

  const [statSold, setStatSold] = useState([]);
  const [statRevenue, setStatRevenue] = useState([]);
  const [statAverageRoomRate, setStatAverageRoomRate] = useState([]);
  
  // const chartshow = () =>{
  //   return new Chart(
  //       document.getElementById('acquisitions'),
  //     {
  //         type: 'bar',
  //         data: {
  //           labels: data.map(row => row.year),
  //           datasets: [
  //             {
  //               label: 'Acquisitions by year',
  //               data: data.map(row => row.count)
  //             }
  //           ]
  //         }
  //       }
  //     );
  // }

  const handleDataStatistic = (data) => {
    let statSoldTemp = []
    let statRevenueTemp = []
    let statAverageRoomRateTemp = []
    for( let i=0 ; i<data.length ; i++ ){
      statSoldTemp.push({
        year  : data[i].key, 
        count : data[i].value.sold,
      })
      statRevenueTemp.push({
        year  : data[i].key, 
        count : data[i].value.revenue,
      })
      statAverageRoomRateTemp.push({
        year  : data[i].key, 
        count : data[i].value.average_room_rate,
      })
    }
    setStatSold(statSoldTemp)
    setStatRevenue(statRevenueTemp)
    setStatAverageRoomRate(statAverageRoomRateTemp)
    setIsAnalyticReady(true)
  }

  useEffect(() => {
    
    if( isAnalyticReady ){
      const data = [
        { year: 2010, count: 10 },
        { year: 2011, count: 20 },
        { year: 2012, count: 15 },
        { year: 2013, count: 25 },
        { year: 2014, count: 22 },
        { year: 2015, count: 30 },
        { year: 2016, count: 28 },
      ];

      const ctx = document.getElementById('acquisitions');
      if (ctx) {
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: statSold.map(row => row.year),
            datasets: [
              {
                label: 'Room Sold',
                data: statSold.map(row => row.count),
              },
              {
                label: 'Revenue',
                data: statRevenue.map(row => row.count),
              },
              {
                label: 'Average Room Rate',
                data: statAverageRoomRate.map(row => row.count),
              },
            ]
          }
        });
      }
    }
  }, [isAnalyticReady]);

  const allHotelData = (datas) => {
    let result = [...datas]
    for( let i=0 ; i<datas.length ; i++ ){
      if( i===0 ) {
        result['selected'] = true;
      }else{
        result['selected'] = false; 
      }
    }
    setAllHotelAvail(result)
    if(result.length > 0){
      setSelectedHotel({id: result[0].id, name: result[0].name})
    }
  }

  const handleSelectHotel = (e) => {
    const splitValue = e.target.value.split("||")
    setSelectedHotel({id: splitValue[0], name: splitValue[1]}) 
  }

  useEffect(()=>{
    if( dataDashboard.dashboardRegionOverview ){
      setRegionOverview(dataDashboard.dashboardRegionOverview)
    }
  },[dataDashboard.dashboardRegionOverview])

  useEffect(()=>{
    if( dataDashboard.dashboardOverviewPerHotelResp ){
      setOverviewHotel(dataDashboard.dashboardOverviewPerHotelResp)
      setIsLoading(false)
    }
  },[dataDashboard.dashboardOverviewPerHotelResp])

  useEffect(()=>{
    if( dataDashboard.dasboardStatisticPerHotelResp ){
      setStatisticHotel(dataDashboard.dasboardStatisticPerHotelResp)
      handleDataStatistic(dataDashboard.dasboardStatisticPerHotelResp)
      setIsLoading(false)
    }
  },[dataDashboard.dasboardStatisticPerHotelResp])

  useEffect(()=>{
    if( dataHotel.hotelResp ){
      if(dataHotel.hotelResp.length >0 ){
        allHotelData(dataHotel.hotelResp)
      }
    }
  },[dataHotel.hotelResp])

  useEffect(()=>{
    if( dataDashboard.dashboardSourceOverviewResp ){
      setSourceData(dataDashboard.dashboardSourceOverviewResp)
    }
  },[dataDashboard.dashboardSourceOverviewResp])

  useEffect(()=>{
    if( localStorage.getItem("role") == "SUPER_ADMIN" && selectedHotel){
      setDashboardOverviewPerHotel(dispatch, selectedHotel.id)
      setDashboardStatisticPerHotel(dispatch, selectedHotel.id)
    }
  },[selectedHotel])

  useEffect(()=>{
    if( localStorage.getItem("role") == "SUPER_ADMIN" ){
      setDashboardRegionOverview(dispatch)
      setDashboardSourceOverview(dispatch)
      setAllHotel(dispatch, 9999)
    }else{
      setDashboardOverviewPerHotel(dispatch, localStorage.getItem('hotelId'))
      setDashboardStatisticPerHotel(dispatch, localStorage.getItem('hotelId'))
      setSelectedHotel({id: localStorage.getItem('hotelId'), name: localStorage.getItem('hotelName')})
    }
  },[dispatch])
    
	return (
		<>
      {selectedHotel && isLoading === false ?
        <Row className="mr-0 ml-0">
          <Col xs={"12"} className={styles.right_container}>
            <Row className={styles.container}> 
              <h2 className="mb-1">
                Dashboard
              </h2>
              {localStorage.getItem("role") == "SUPER_ADMIN" &&
                <>
                  <Form.Select aria-label="Default select example" 
                    className={styles.field_form} 
                    onChange={ (e)=> handleSelectHotel(e) }
                  >
                    {allHotelAvail.map((oneData, index) =>{
                      return (
                        <option
                          value={`${oneData.id}||${oneData.name}`} 
                          key={index} 
                          selected={oneData.name === selectedHotel.name ? true : false}
                        >
                          {oneData.name}
                        </option>
                        )
                    } )}
                  </Form.Select>
                </>
              }
            </Row>
            
            {overviewHotel && 
              <>
                <Row className={styles.container}> 
                  <h4>Overview</h4>
                    <Col xs={12}>
                      <Row className={styles.section_reservation}>
                        <Col xs={2} className="text-center">
                          <p className={styles.today}> Today's </p>
                          <p className="m-0"> Check-in</p>
                          <p className={styles.numberOverview}> {overviewHotel?.today_check_in || 0} </p>
                        </Col>
                        <Col xs={2} className="text-center">
                          <p className={styles.today}> Today's </p>
                          <p className="m-0"> Check-out</p>
                          <p className={styles.numberOverview}> {overviewHotel?.today_check_out || 0} </p>
                        </Col>
                        <Col xs={2} className="text-center">
                          <p className={styles.today}> Total </p>
                          <p className="m-0">Booking</p>
                          <p className={styles.numberOverview}> {overviewHotel?.total_booking || 0} </p>
                        </Col>
                        <Col xs={3} className="text-center">
                          <p className={styles.today}> Total </p>
                          <p className="m-0"> Complete Booking</p>
                          <p className={styles.numberOverview}> {overviewHotel?.total_completed_booking || 0} </p>
                        </Col>
                        <Col xs={3} className="text-center">
                          <p className={styles.today}> Total </p>
                          <p className="m-0"> Canceled Booking</p>
                          <p className={styles.numberOverview}> {overviewHotel?.total_cancelled_booking || 0} </p>
                        </Col>
                      </Row>
                    </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Row className={styles.container2}> 
                      <h4>Booking Overview</h4>
                        <Col xs={12} className="p-2">
                          <Row className={styles.section_reservation}>
                            <Row className="pl-2">
                              <Col xs={4} className="text-center">
                                <p className={styles.today}> Today's <br/><strong>Booking</strong> </p>
                                <p className={styles.numberOverview}> {overviewHotel?.today_booking || 0} </p>
                              </Col>
                              <Col xs={4} className="text-center">
                                <p className={styles.today}> This Month <br/><strong>Booking</strong> </p>
                                <p className={styles.numberOverview}> {overviewHotel?.this_month_booking || 0} </p>
                              </Col>
                              <Col xs={4} className="text-center">
                                <p className={styles.today}> This Year<br/><strong>Booking</strong> </p>
                                <p className={styles.numberOverview}> {overviewHotel?.this_year_booking || 0} </p>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col xs={4} className="text-center">
                                <p className={styles.today}> Today's <br/><strong>Cancel</strong> </p>
                                <p className={styles.numberOverview}> {overviewHotel?.today_cancelled || 0} </p>
                              </Col>
                              <Col xs={4} className="text-center">
                                <p className={styles.today}> this Month <br/><strong>Cancel</strong> </p>
                                <p className={styles.numberOverview}> {overviewHotel?.this_month_cancelled || 0} </p>
                              </Col>
                              <Col xs={4} className="text-center">
                                <p className={styles.today}> This Year <br/><strong> Canceled</strong> </p>
                                <p className={styles.numberOverview}> {overviewHotel?.this_year_cancelled || 0} </p>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Row className={styles.container2}> 
                      <h4>Revenue Overview</h4>
                        <Col xs={12} >
                          <Row className={styles.section_reservation}>
                            <Row className="pl-2">
                              <Col xs={6}>
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <p className={styles.today}> Total Revenue </p>
                                    <p className={styles.numberRevenue}> {toRupiah(overviewHotel?.total_revenue, { dot: '.', floatingPoint: 0 } || 0)} </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={6}>
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <p className={styles.today}> Average Room Rate </p>
                                    <p className={styles.numberRevenue}> {toRupiah(overviewHotel?.average_room_rate, { dot: '.', floatingPoint: 0 } || 0)} /month </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col xs={4}>
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <p className={styles.today}> Today's Revenue </p>
                                    <p className={styles.numberRevenue}> {toRupiah(overviewHotel?.today_revenue, { dot: '.', floatingPoint: 0 } || 0)} </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={4}>
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <p className={styles.today}> Month's Revenue </p>
                                    <p className={styles.numberRevenue}> {toRupiah(overviewHotel?.this_month_revenue, { dot: '.', floatingPoint: 0 } || 0)} </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={4}>
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <p className={styles.today}> Year's Revenue </p>
                                    <p className={styles.numberRevenue}> {toRupiah(overviewHotel?.this_year_revenue, { dot: '.', floatingPoint: 0 } || 0)} </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }

            {statisticHotel && localStorage.getItem("role") != "AFFILIATOR" && 
              <Row className={styles.container}> 
                <h4>Analytics</h4>
                  <canvas xs={12} id="acquisitions"/>
              </Row>
            }
            
            {regionOverview && 
              <Row className={styles.container}> 
                <h4>Revenue per Region</h4>
                  <Col xs={12}>
                  <Table className={styles.table}>
                    <thead>
                      <tr className={styles.table_head}>
                        <th>
                          <p className={styles.th_text}> Province </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> City </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> Total Revenue </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> Today's Revenue </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> This Month's Revenue </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> This Year's Revenue </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    {regionOverview.map((oneData, index) =>{
                      return (
                        <tr>
                          <td>{oneData.province}</td>
                          <td>{oneData.city}</td>
                          <td>{toRupiah(oneData?.total_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                          <td>{toRupiah(oneData?.total_today_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                          <td>{toRupiah(oneData?.total_month_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                          <td>{toRupiah(oneData?.total_year_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                        </tr>
                        )
                    } )}
                    </tbody>
                  </Table>

                  </Col>
              </Row>
            }
            
            {sourceData && localStorage.getItem("role") == "SUPER_ADMIN" &&
              <Row className={styles.container}>
                <h4>Revenue per Booking Source</h4>
                  <Col xs={12}>
                  <Table className={styles.table}>
                    <thead>
                      <tr className={styles.table_head}>
                        <th>
                          <p className={styles.th_text}> Booking Source </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> Total Revenue </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> Today's Revenue </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> This Month's Revenue </p>
                        </th>
                        <th>
                          <p className={styles.th_text}> This Year's Revenue </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    {sourceData.map((oneData, index) =>{
                      return (
                        <tr>
                          <td>{oneData.booking_source}</td>
                          <td>{toRupiah(oneData?.total_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                          <td>{toRupiah(oneData?.total_today_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                          <td>{toRupiah(oneData?.total_month_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                          <td>{toRupiah(oneData?.total_year_revenue, { dot: '.', floatingPoint: 0 } || 0)}</td>
                        </tr>
                        )
                    } )}
                    </tbody>
                  </Table>

                  </Col>
              </Row>
            }
          </Col>
        </Row>
        :
        <>
          <h2 className="mb-1 mt-10 ml-2">
            Dashboard
          </h2>
          <p className="ml-2">
            Currently no hotel registered
          </p>
        </>
      }
		</>
	);
};


const storage = state => {
  return {
    dataDashboard: state.dashboard,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(Dashboard)  
