import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Row, Col } from 'react-bootstrap'
import { 
  ReservationPage,
  ReservationDetailPage,
  AddReservationPage,
  BasicKnowledgePage,
  PropertyDetailPage,
  AddPropertyPage,
  NotFoundPage,
  LoginPage,
  AdminPage,
  AddAdminPage,
  AdminDetailPage,
  PropertyPage,
  PrivacyPolicyPage,
  TermsAndConditionPage,
  ContactUsPage,
  AboutHmsPage,
  AccountPage,
  DashboardPage,
  InvoicePage,
  ReportingPage,
  AdminHotelDetailPage,
  RoomDetailPage,
  AfiliatorDetailPage,
  AfiliatorPage,
  AddAfiliatorPage,
} from './pages'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideMenu from "./components/SideMenu/SideMenu";
import Header from "./components/Header/Header";

function RouteHandler() {
  const [isLogin, setIsLogin] = useState("")
  const [token, setToken] = useState("")
  let location = useLocation();

  useEffect(()=>{
    if( localStorage.getItem('token') && localStorage.getItem('webHMSLogedIn') ){
      setIsLogin(true)
      setToken(localStorage.getItem('token'))
    } else {
      setIsLogin(false)
    }
  },[location])

  return (
    <>
      { isLogin !== "" && 
        <Row>
          { isLogin ?
            <>
              <Header isLogin={isLogin} token={token} needLogin={true}/>
              <Col xs="2" className="side_menu">
                <SideMenu/>
              </Col>
              <Col xs={"9"} className={"main_menu_login"}>

                {/* ROLE 1 === super admin */}
                { localStorage.getItem('role') === 'SUPER_ADMIN' && 
                  <Routes>
                    <Route exact path="/" element={<DashboardPage/>} />
                    <Route exact path="/dashboard" element={<DashboardPage/>} />
                    <Route exact path="/account" element={<AccountPage/>} />
                    <Route exact path="/reporting" element={<ReportingPage/>} />

                    <Route exact path="/reservation" element={<ReservationPage/>} />
                    <Route exact path="/reservationDetail/:reservationId" element={<ReservationDetailPage/>} />
                    <Route exact path="/reservation/addReservation" element={<AddReservationPage/>} />
                    <Route exact path="/reservationDetail/:reservationId/invoice" element={<InvoicePage/>} />

                    <Route exact path="/property" element={<PropertyPage/>} />
                    <Route exact path="/propertyDetail/:propertyId" element={<PropertyDetailPage/>} />
                    <Route exact path="/propertyDetail/:propertyId/room/:roomId" element={<RoomDetailPage/>} />
                    <Route exact path="/property/addProperty" element={<AddPropertyPage/>} />

                    <Route exact path="/adminManagement" element={<AdminPage/>} />
                    <Route exact path="/adminDetail/:hotelId" element={<AdminDetailPage/>} />
                    <Route exact path="/adminManagement/addAdmin" element={<AddAdminPage/>} />
                    <Route exact path="/adminDetail/adminHotel/:adminId" element={<AdminHotelDetailPage/>} />

                    <Route exact path="/afiliatorManagement" element={<AfiliatorPage/>} />
                    <Route exact path="/afiliatorDetail/:affId" element={<AfiliatorDetailPage/>} />
                    <Route exact path="/afiliatorManagement/addAffiliator" element={<AddAfiliatorPage/>} />

                    <Route exact path="/privacyPolicy" element={<PrivacyPolicyPage/>} />
                    <Route exact path="/termsAndCondition" element={<TermsAndConditionPage/>} />
                    <Route exact path="/contactUs" element={<ContactUsPage/>} />
                    <Route exact path="/aboutHms" element={<AboutHmsPage/>} />
                    <Route exact path="/basicKnowledge" element={<BasicKnowledgePage/>} />
                    <Route exact path="/404" element={<NotFoundPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                  </Routes>
                }
                
                {/* ROLE 2 === admin hotel  */}
                { localStorage.getItem('role') === 'ADMIN' && 
                  <Routes>
                    <Route exact path="/" element={<DashboardPage/>} />
                    <Route exact path="/account" element={<AccountPage/>} />
                    <Route exact path="/dashboard" element={<DashboardPage/>} />
                    <Route exact path="/reporting" element={<ReportingPage/>} />

                    <Route exact path="/adminManagement" element={<AdminPage/>} />
                    <Route exact path="/adminDetail/:hotelId" element={<AdminDetailPage/>} />
                    
                    <Route exact path="/reservation" element={<ReservationPage/>} />
                    <Route exact path="/reservationDetail/:reservationId" element={<ReservationDetailPage/>} />
                    <Route exact path="/reservation/addReservation" element={<AddReservationPage/>} />
                    <Route exact path="/reservationDetail/:reservationId/invoice" element={<InvoicePage/>} />

                    {/* <Route exact path="/property/addProperty" element={<AddPropertyPage/>} /> */}
                    <Route exact path="/property" element={<PropertyPage/>} />
                    {/* <Route exact path="/propertyDetail/:propertyId" element={<PropertyDetailPage/>} /> */}
                    
                    <Route exact path="/privacyPolicy" element={<PrivacyPolicyPage/>} />
                    <Route exact path="/termsAndCondition" element={<TermsAndConditionPage/>} />
                    {/* <Route exact path="/contactUs" element={<ContactUsPage/>} />
                    <Route exact path="/aboutHms" element={<AboutHmsPage/>} />
                    <Route exact path="/basicKnowledge" element={<BasicKnowledgePage/>} /> */}
                    <Route exact path="/propertyDetail" element={<PropertyPage/>} />
                    <Route exact path="/404" element={<NotFoundPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                  </Routes>
                }
                
                {/* ROLE 2 === Affiliator  */}
                { localStorage.getItem('role') === 'AFFILIATOR' && 
                  <Routes>
                    <Route exact path="/" element={<DashboardPage/>} />
                    <Route exact path="/account" element={<AccountPage/>} />
                    <Route exact path="/dashboard" element={<DashboardPage/>} />

                    <Route exact path="/reservation" element={<ReservationPage/>} />
                    <Route exact path="/reservationDetail/:reservationId" element={<ReservationDetailPage/>} />
                    <Route exact path="/reservation/addReservation" element={<AddReservationPage/>} />
                    <Route exact path="/reservationDetail/:reservationId/invoice" element={<InvoicePage/>} />

                    <Route exact path="/privacyPolicy" element={<PrivacyPolicyPage/>} />
                    <Route exact path="/termsAndCondition" element={<TermsAndConditionPage/>} />
                    {/* <Route exact path="/contactUs" element={<ContactUsPage/>} />
                    <Route exact path="/aboutHms" element={<AboutHmsPage/>} />
                    <Route exact path="/basicKnowledge" element={<BasicKnowledgePage/>} /> */}
                    <Route exact path="/404" element={<NotFoundPage/>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                  </Routes>
                }
              </Col>
            </>
          :
            // not login route
            <>
              <Header isLogin={isLogin} token={token} needLogin={false}/>
              <Col xs={"12"} className={"main_menu"}>
                <Routes>
                  <Route exact path="/" element={<LoginPage/>} />
                  <Route exact path="/login" element={<LoginPage/>} />
                  <Route exact path="/privacyPolicy" element={<PrivacyPolicyPage/>} />
                  <Route exact path="/termsAndCondition" element={<TermsAndConditionPage/>} />
                  <Route exact path="/contactUs" element={<ContactUsPage/>} />
                  <Route exact path="/aboutHms" element={<AboutHmsPage/>} />
                  <Route exact path="/basicKnowledge" element={<BasicKnowledgePage/>} />
                  <Route exact path="/404" element={<NotFoundPage/>}/>
                  <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
              </Col>
            </>
          }
        </Row>
      }
    </>
  );
}

export default RouteHandler;
