import React, { useEffect } from "react";
import AfiliatorTable from '../components/Table/AfiliatorTable'

const AfiliatorPage = () => {
  useEffect(()=>{
    localStorage.setItem("pagePos","AfiliatorPage")
  },[])

  return (    
    <div className="container_right_form">
      <AfiliatorTable
        pageName={"Afiliator"}
        linkAddNew={"../newShipyardOwner"}
      />
    </div>
  );
};

export default AfiliatorPage;
