import { combineReducers } from "redux"
import loginRegister from "./loginRegisterReducer"
import admin from "./adminReducer"
import afiliator from "./afiliatorReducer"
import property from "./propertyReducer"
import reservation from "./reservationReducer"
import hotel from "./hotelReducer"
import dashboard from "./dashboardReducer"

export default combineReducers({
  loginRegister,
  admin,
  afiliator,
  property,
  reservation,
  hotel,
  dashboard,
})
