import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setAllProperty= async (dispatch, page, hotelId, paramFilter) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : paramFilter,
    url     : `${process.env.REACT_APP_API_URI}/property?page=${page}`,
  }

  if( hotelId ){
    options.url = options.url + `&hotel_id=${hotelId}`
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_PROPERTY', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllAvailRoomByDate= async (dispatch, paramFilter, page) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : paramFilter,
    url     : `${process.env.REACT_APP_API_URI}/reservation/available?page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_AVAIL_ROOM', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setAllHotelProperty= async (dispatch, page) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    url     : `${process.env.REACT_APP_API_URI}/property?hotel_id=${localStorage.getItem("hotelId")}&page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_PROPERTY', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllRoomProperty= async (dispatch, property_id, page=0) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    url     : `${process.env.REACT_APP_API_URI}/property/${property_id}/room?page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_ROOM', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setOneProperty = async (dispatch, propertyId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/property/${propertyId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_PROPERTY_DETAIL_RESP', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setOneRoom = async (dispatch, propertyId, roomId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/property/${propertyId}/room/${roomId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_ROOM_DETAIL_RESP', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setCreateProperty = async (dispatch, data) => {
  axios.post(`${process.env.REACT_APP_API_URI}/property`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_CREATE_PROPERTY', payload: data })
    checkResponseMessage(true, "Success", "Property Added Successfully")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setCreateRoom = async (dispatch, data, propertyId) => {
  axios.post(`${process.env.REACT_APP_API_URI}/property/${propertyId}/room`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_CREATE_ROOM', payload: {isSuccess:true} })
    checkResponseMessage(true, "Success", "Room Added Successfully")
  }).catch((error)=>{
    dispatch({ type: 'SET_CREATE_ROOM', payload: {isSuccess:false} })
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setUpdateDetailProperty = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/property/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_PROPERTY_RESP', payload: data })
    checkResponseMessage(true, "Success", "Property Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateDetailRoom = async (dispatch, data, propertyId, roomId) => {
  axios.put(`${process.env.REACT_APP_API_URI}/property/${propertyId}/room/${roomId}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_ROOM_RESP', payload: data })
    checkResponseMessage(true, "Success", "Room Information Updated")
    setTimeout(() => { 
      window.history.go(-1);
    }, 1000)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setDeletePropertyById = async (dispatch, propertyId) => {
  axios.delete(`${process.env.REACT_APP_API_URI}/property/${propertyId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_DELETE_PROPERTY_RESP', payload: data })
    checkResponseMessage(true, "Sukses", "Sukses menghapus Property Hotel")
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setSearchOrders = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/property`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_PROPERTY_SEARCH', payload: data.result.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setChangeOrderStatus = async (dispatch, orderId, params) => {
  const options = {
    method  : 'patch',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    data    : params,
    url     : `${process.env.REACT_APP_API_URI}/property/${orderId}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_CHANGE_PROPERTY_STATUS', payload: data.result })
    checkResponseMessage(true, "Sukses", "Sukese mengganti status")
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setDetailProperty= async (dispatch, id) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    url     : `${process.env.REACT_APP_API_URI}/property/${id}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_PROPERTY_DETAIL', payload: data.result.data[0] })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const resetAllAvailRoom = async (dispatch) => {
  dispatch({ type: 'RESET_ALL_AVAIL_ROOM'})
}