import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Form, InputGroup, } from 'react-bootstrap';
import 'rsuite/dist/rsuite.min.css';
import { BiSearchAlt } from 'react-icons/bi'
import { Link } from "react-router-dom";
import styles from './BaseTable.module.scss';
import BaseTable from "./BaseTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { setAllReservationReporting } from '../../store/actions/reservationAction'
import { setAllHotel } from '../../store/actions/hotelAction'
import { toRupiah } from 'to-rupiah';
import { generateCsv  } from 'export-to-csv';
import { AiOutlineConsoleSql } from "react-icons/ai";

const ReportingTable = ({
  pageName,
  dispatch, 
  dataReservation,
  dataHotel,
}) => {

  const [status, setStatus] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [name, setName] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [allDataDownload, setAllDataDownload] = useState([])
  const [filterHotel, setFilterHotel] = useState([])
  const [roomType, setRoomType] = useState([])
  const [rawData, setRawData] = useState([])
  const [totalAmount, setTotalAmount] = useState([])
  const [reserveTime, setReserveTime] = useState([])
  const [allAvailHotel, setAllAvailHotel] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [searchKeyword, setSearchKeyword] = useState(null)

  const doSearch = (e) => {
    e.preventDefault()
    let params = {}
    if( searchKeyword ){
      params['name'] = searchKeyword
    }
    if( status ){
      params['status'] = status
    }
    if( startDate && endDate ){
      params['checkin_date_start'] = Math.floor(new Date(startDate).getTime())
      params['checkin_date_end'] = Math.floor(new Date(endDate).getTime())
    }
    
    if( localStorage.getItem("is_super_admin") === 'false' ){
      params['hotel_id'] = localStorage.getItem("hotelId")
    }else{
      if( filterHotel ){
        params['hotel_id'] = filterHotel
      }
    }
    setAllReservationReporting(dispatch, params, 0)
  }
 
  const doClearFilter = (e) => {
    let params = {search: ""}
   
    setSearchKeyword("")
    
    if( localStorage.getItem("is_super_admin") === 'false' ){
      params['hotel_id'] = localStorage.getItem("hotelId")
    }
    if( startDate && endDate ){
      params['start'] = Math.floor(new Date(startDate).getTime() / 1000)
      params['end'] = Math.floor(new Date(endDate).getTime() / 1000)
    }
    setAllReservationReporting(dispatch, params, 0)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    
    if( localStorage.getItem("is_super_admin") === 'true' ){
      setAllReservationReporting(dispatch, "", (pageNumber-1))
    }else{
      setAllReservationReporting(dispatch,  {'hotel_id': localStorage.getItem('hotelId')}, (pageNumber-1))
    }
  }

  const setDataShown = (datas) => {
    let listData = []
    for (let idx in datas) {
      listData.push({
        'ID': datas[idx].id,
        'Nama Tamu': datas[idx].guest_name,
        'Nama Hotel': datas[idx].hotel_name,
        'Tipe Kamar': datas[idx].property.type,
        'Book Time': datas[idx].start_date + " -> " + datas[idx].end_date,
        'Total Amount': toRupiah(datas[idx].price, { dot: '.', floatingPoint: 0 }),
        'Status Booking': datas[idx].status,
      })
    }
    setData(listData)
  }
  
  const convertCsv = (e) => {
    e.preventDefault()
  
    // Assuming `data` is the data you want to export
   // Convert array of objects to CSV string
   const headers = Object.keys(allDataDownload[0]).join(","); // Create CSV headers
   const rows = allDataDownload.map(row => 
     Object.values(row).map(value => `"${value}"`).join(",")
   );
 
   const csvContent = [headers, ...rows].join("\n");
 
   // Create blob and initiate download
   const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
   const url = URL.createObjectURL(csvBlob);
 
   const link = document.createElement("a");
   link.href = url;
   link.setAttribute("download", "exported_data.csv");
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
   URL.revokeObjectURL(url);
  }

  const checkStatus = (status) =>{
    if(status == 1){
      return "arrival"
    }else if(status == 2){
      return "check in"
    }else if(status == 3){
      return "check out"
    }else if(status == 4){
      return "no show"
    }else if(status == 5){
      return "cancelled"
    }
  }

  const setDataDownload = (allData) => {
    let datas = []
    for (let i = 0; i < allData.length; i++) {
      datas.push({
        id: allData[i].id,
        name: allData[i].guest_name,
        email: allData[i].email,
        phone: allData[i].phone,
        notes: allData[i].notes,
        booking_source: allData[i].booking_source,
        price: allData[i].price,
        guest: allData[i].guest,
        check_in: allData[i].start_date,
        check_out: allData[i].end_date,
        hotel_name: allData[i].hotel_name,
        province: allData[i].province,
        city: allData[i].city,
        room_type: allData[i].property.type,
        capacity: allData[i].property.capacity,
        status: checkStatus(allData[i].status),
      })
    }
    setAllDataDownload(datas)
  }

	useEffect(()=>{
    if( localStorage.getItem("is_super_admin") === 'true' ){
      setAllReservationReporting(dispatch, "", 0)
    }else{
      setAllReservationReporting(dispatch,  {'hotel_id': localStorage.getItem('hotelId')}, 0)
    }
    setAllHotel(dispatch, 9999)
	},[dispatch])

  useEffect(()=>{
    if( dataReservation.allReservationReportResp ){
      setDataDownload(dataReservation.allReservationReportResp.data)
      setDataShown(dataReservation.allReservationReportResp.data)
      setPagination({
        total: dataReservation.allReservationReportResp.total, 
      })
      
    }
  },[dataReservation.allReservationReportResp])

  useEffect(()=>{
    if( dataHotel.hotelResp ){
      setAllAvailHotel(dataHotel.hotelResp)
      setIsLoading(false)
    }
  },[dataHotel.hotelResp])

	return (
    !isLoading &&
    <>
      <Container className={styles.container}>
        <Row>
          <Col xs={{ span:3 }} className="mt-4">
            <p className={styles.main_title}>
              {pageName}
            </p>
          </Col>
          <Col xs={{ span:3, offset:6 }} className="mt-4">
            <Button className={styles.add_button} onClick={(e)=>convertCsv(e)}>
              {"Download Report"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="3">
            { localStorage.getItem("is_super_admin") === 'true' &&
              <>
                <Form.Label htmlFor="basic-url">Filter by Hotel</Form.Label>
                <Form.Select aria-label="Choose Status" className={styles.field_form} 
                  onChange={ (e)=> setFilterHotel(e.target.value)}
                  >
                  <option value={""}>{"Pilih Hotel"}</option>
                  {allAvailHotel.map( (item, index)=>(
                    <option key={index} value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </>
            }
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="3">
            <Form.Label htmlFor="basic-url">Filter by Status</Form.Label>
            <Form.Select aria-label="Choose Status" className={styles.field_form} 
              onChange={ (e)=> setStatus(e.target.value)}
              // setStatus(e.target.value)
              // 1 - arrival
              // 2 - check in
              // 3 - check out
              // 4 - no show
              // 5 - cancelled
            >
              <option value="">{"Select Status"}</option>
              <option value="1">{"Arrival"}</option>
              <option value="2">{"Check In"}</option>
              <option value="3">{"Check Out"}</option>
              <option value="4">{"No Show"}</option>
              <option value="5">{"Cancelled"}</option>
            </Form.Select>
          </Col>
          <Col xs="3">
            <Form.Label htmlFor="basic-url">Rentan Tanggal Checkin</Form.Label>
            <br/>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Pilih Rentan Tanggal"
              className={styles.date_picker}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
          </Col>
          <Col xs={{span: "3", offset:"3"}} className="mt-4">
            <Button className={styles.save_button} onClick={(e)=>doSearch(e)}>
              {"Apply"}
            </Button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Button className={styles.cancel_button} onClick={(e)=>doClearFilter(e)} >
              {"Reset"}
            </Button>
          </Col>
        </Row>
        {data.length > 0 ?
          <BaseTable 
            data={data} 
            // linkDetail={"../reservationDetail/"} 
            pagination={pagination}
            section={"reservation"}
            activePage={activePage}
            handlePageChange={handlePageChange}
          />
          :
          <>
            <br/>
            <br/>
            <p>
              Curently no data..
            </p>
          </>
        }
      </Container>
    </>
	);
};

const storage = state => {
  return {
    dataReservation: state.reservation,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(ReportingTable)  