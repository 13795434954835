import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { setCreateAfiliator } from '../store/actions/afiliatorAction'
import { checkResponseMessage } from "../helper/helper";
import { useNavigate } from 'react-router-dom';

const AddAfiliatorPage = ({ dispatch, dataAfiliator }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [availProvince, setAvailProvince] = useState([]);
  const [availCity, setAvailCity] = useState([]);

  const doCreate = (e) => {
    e.preventDefault()
    if(name == "" || code == "" || email == "" || password == "" || confirm_password == ""){
      checkResponseMessage(false, "Error", "Mohon isi semua data")
    }else{
      const dataCreate = {
        name,
        email,
        password,
        confirm_password,
        affiliator_code: code,
        role: "AFFILIATOR"
      }
      setCreateAfiliator(dispatch, dataCreate)
    }
  }
  

  useEffect(()=>{
    if( dataAfiliator.createAfiliatorResp ){
      setIsLoading(false)
      navigate(-1);
    }
  },[dataAfiliator.createAfiliatorResp])

  const dataForm = [
    {
      label: "Nama",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: name,
      action: setName,
    },{
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Kode Afiliator",
      type: "text",
      placeholder: "Masukkan Kode Afiliator",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: code,
      action: setCode,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Password",
      type: "password",
      placeholder: "Masukkan Password",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: password,
      action: setPassword,
    },{
      label: "Ulangi Password",
      type: "password",
      placeholder: "Masukkan Lago Password",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: confirm_password,
      action: setConfirm_password,
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../afiliatorManagement',
      spaceXs: "3",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Tambah Afiliator"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        email={email}
        onSubmit={doCreate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataAfiliator: state.admin,
  };
};

export default connect(
  storage
)(AddAfiliatorPage)
