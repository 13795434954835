import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { checkResponseMessage } from "../helper/helper";
import { setAllRoomProperty, setCreateRoom, setOneProperty, setUpdateDetailProperty, setDeletePropertyById } from '../store/actions/propertyAction'
import { setAllHotel } from '../store/actions/hotelAction'
import Swal from 'sweetalert2';

const PropertyDetailPage = ({ dispatch, dataProperty, dataHotel }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [onProgress, setOnProgress] = useState(false);
  const { propertyId } = useParams()
  const [hotelId, seHotelId] = useState("");
  const [activePage, setActivePage] = useState(1)

  const [hotelName, setHotelName] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomCapacity, setRoomCapacity] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [availHotels, setAllAvailHotels] = useState([]);
  const [dataCurrAllRoom, setDataCurrAllRoom] = useState([]);
  const [pagination, setPagination] = useState({})
  const [totalRoom, setTotalRoom] = useState("");
  const [roomValue, setRoomValue] = useState({
    room_number: "",
  });
  const [dataNewRoom, setDataNewroom] = useState([]);

  const doUpdate = (e) => {
    e.preventDefault()
    const dataUpdate = {
      hotel_id: hotelId,
      type: roomType,
      capacity: roomCapacity,
      rooms: dataCurrAllRoom.length,
    }
    setUpdateDetailProperty(dispatch, dataUpdate, propertyId)
  }

  const doDelete = async (e) => {
    e.preventDefault()
    const needConfirmation = await checkResponseMessage(false, "Menghapus property", "Apakah anda yakin untuk menghapus property hotel ini?", true)
    if(needConfirmation){
      setDeletePropertyById(dispatch, propertyId)
    }
  }

  const roomValueHandle = (value, section) =>{
    const curr = {...roomValue}
    if(section === "room_number"){
      curr.room_number = value
    }
    setRoomValue(curr)
  }

  const addRoomAction = (e) => {
    e.preventDefault()
    const newRoom = {
      rooms_data : [{room_number: roomValue.room_number, active: 1}],
    }
    setOnProgress(true)
    setCreateRoom(dispatch, newRoom, propertyId)
  }

  // const doCreateProperty = (e) => {
  //   e.preventDefault()
  //   if(roomDatas.length === 0){
  //     Swal.fire({
  //       title: 'Warning',
  //       text: 'Masukkan setidaknya 1 kamar untuk tipe kamar ini',
  //       icon: 'warning',
  //       confirmButtonColor: '#1b4460',
  //     })
  //   }else{
  //     const name = localStorage.getItem('hotelId') || selectedHotel.id
  //     const dataCreate = {
  //       hotel_id: name,
  //       type: roomType,
  //       capacity: roomCapacity,
  //       rooms: totalRoom,
  //       rooms_data: roomDatas
  //     }
  //     setCreateProperty(dispatch, dataCreate)
  //   }
  // }
  
  const handleSelect = (e) => {
    const splitValue = e.target.value.split("||")
    setSelectedHotel({id: splitValue[0], name: splitValue[1]}) 
  }

  const setDataShown = (datas) => {
    let listData = []
    for (let idx in datas) {
      listData.push({
        'ID': datas[idx].id,
        'Nomor Kamar': datas[idx].room_number,
        'Status': datas[idx].active === 1 ? "aktif" : "tidak aktif",
      })
    }
    setDataCurrAllRoom(listData)
    setIsLoading(false)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    setAllRoomProperty(dispatch, propertyId, pageNumber-1)
  }
  
  useEffect(()=>{
    if( dataProperty.createRoomResp ){
      setOnProgress(false)
      if( dataProperty.createRoomResp.isSuccess){
        const dataRoom = [...dataNewRoom]
        dataRoom.push({room_number: roomValue.room_number, active: 1})
        setDataNewroom(dataRoom)
      }
    }
  },[dataProperty.createRoomResp])

  useEffect(()=>{
    if( dataProperty.propertyDetailResp ){
      let data = dataProperty.propertyDetailResp
      seHotelId(data.hotel_id)
      setHotelName(data.hotel_name)
      setRoomType(data.type)
      setRoomCapacity(data.capacity)
      setTotalRoom(data.rooms)
    }
  },[dataProperty.propertyDetailResp])

  useEffect(()=>{
    if( dataProperty.roomResp ){
      setDataShown(dataProperty.roomResp.data)
      setPagination({
        offset: dataProperty.roomResp.total_pages, 
        limit: 10, 
        total: dataProperty.roomResp.total, 
      })
      // setDataCurrAllRoom(dataProperty.roomResp.data)
    }
  },[dataProperty.roomResp])

  useEffect(()=>{
    setOneProperty(dispatch, propertyId)
    setAllRoomProperty(dispatch, propertyId)
    seHotelId(propertyId)
  },[dispatch, propertyId])

  useEffect(()=>{
    if( dataProperty.createPropertyResp ){
    }
  },[dataProperty.createPropertyResp])

  useEffect(()=>{
    if( dataHotel.hotelResp ){
      // set id : .. , name: ...
      setAllAvailHotels(dataHotel.hotelResp)
      setSelectedHotel(dataHotel.hotelResp[0])
      setTimeout(() => {
        setIsLoading(false)
    }, 1000);
    }
  },[dataHotel.hotelResp])

  useEffect(()=>{
    if( localStorage.getItem('is_super_admin') === 'true' ){
      setAllHotel(dispatch, 9999)
    }else{
      setHotelName(localStorage.getItem('hotelName'))
      setIsLoading(false)
    }
  },[])

  const dataForm = [
    {
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "Total Kamar (dihitung otomatis)",
      type: "text",
      placeholder: "Masukkan total kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: totalRoom,
      action: setTotalRoom,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Tambah Kamar Baru",
      type: "tableRoom",
      placeholder: "Tambah Kamar Baru",
      spaceMd: "12",
      spaceXs: "12",
      valueRoom: roomValue,
      action: addRoomAction,
      valueNew: dataNewRoom,
      valueCurr : dataCurrAllRoom,
      actionOnChange : roomValueHandle,
      activePage: activePage,
      pagination: pagination,
      handlePageChange: handlePageChange,
      propertyId: propertyId,
    }
  ]

  const dataFormSuperAdmin = [
    {
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "Total Kamar (dihitung otomatis)",
      type: "text",
      placeholder: "Masukkan total kamar ",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: dataCurrAllRoom?.length,
      notEditable: true,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Tambah Kamar Baru",
      type: "tableRoom",
      progress: onProgress,
      placeholder: "Tambah Kamar Baru",
      spaceMd: "12",
      spaceXs: "12",
      valueCurr : dataCurrAllRoom,
      valueRoom: roomValue,
      action: addRoomAction,
      valueNew: dataNewRoom,
      actionOnChange : roomValueHandle,
      activePage: activePage,
      pagination: pagination,
      handlePageChange: handlePageChange,
      propertyId: propertyId,
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Property - " + hotelName + " - " + roomType}
        dataForm={localStorage.getItem('is_super_admin') === 'true' ? dataFormSuperAdmin : dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        onSubmit={doUpdate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataProperty: state.property,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(PropertyDetailPage)
