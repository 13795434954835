import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setAllAdmin = async (dispatch, page, filter) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : filter ,
    url     : `${process.env.REACT_APP_API_URI}/admin?page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_ADMIN', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllHotel = async (dispatch, page, filter) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : filter ,
    url     : `${process.env.REACT_APP_API_URI}/hotel?page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_HOTEL', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAdminMySelf = async (dispatch, adminId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/admin/myself`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_ADMIN_MYSELF_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setCreateAdmin = async (dispatch, data) => {
  axios.post(`${process.env.REACT_APP_API_URI}/admin/v2`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_CREATE_ADMIN', payload: data })
    checkResponseMessage(true, "Sukses", "Berhasil menambahkan Admin baru")
    window.location.reload();
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setCreateHotel = async (dispatch, data) => {
  axios.post(`${process.env.REACT_APP_API_URI}/hotel`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_CREATE_HOTEL', payload: data })
      checkResponseMessage(true, "Sukses", "Berhasil menambahkan Hotel baru")
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setUpdatePassAdmin = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/admin/${id}/password`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_PASS_ADMIN_RESP', payload: data })
    checkResponseMessage(true, "Success", "Admin Password Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateDetailAdmin = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/admin/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_ADMIN_RESP', payload: data })
    checkResponseMessage(true, "Success", "Admin Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateDetailHotel = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/hotel/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_HOTEL_RESP', payload: data })
    checkResponseMessage(true, "Success", "Hotel Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setHotelAdminListById = async (dispatch, hotelId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/admin?hotel_id=${hotelId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_ADMIN_LIST_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setHotelAdminDetailById = async (dispatch, adminId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/admin/${adminId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_ADMIN_DETAIL_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setHotelById = async (dispatch, adminId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/hotel/${adminId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_HOTEL_DETAIL_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setAdminUpdateMySelf = async (dispatch, data) => {
  axios.put(`${process.env.REACT_APP_API_URI}/admin`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_SELF_UPDATE_ADMIN_RESP', payload: data })
    checkResponseMessage(true, "Success", "Admin Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateHotelAdminDetail = async (dispatch, data, adminId) => {
  axios.put(`${process.env.REACT_APP_API_URI}/admin/v2/${adminId}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_HOTEL_ADMIN_RESP', payload: data })
    checkResponseMessage(true, "Success", "Admin Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setAdminPassMySelf = async (dispatch, data) => {
  axios.put(`${process.env.REACT_APP_API_URI}/auth/change-password`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_SELF_UPDATE_PASS_ADMIN_RESP', payload: data })
    checkResponseMessage(true, "Success", "Success Change Password")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setDeleteAdminById = async (dispatch, adminId) => {
  axios.delete(`${process.env.REACT_APP_API_URI}/admin/v2/${adminId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_DELETE_ADMIN_RESP', payload: data })
    checkResponseMessage(true, "Sukses", "Sukses menghapus Admin Hotel")
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setDeleteHotelById = async (dispatch, hotelId) => {
  axios.delete(`${process.env.REACT_APP_API_URI}/hotel/${hotelId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_DELETE_HOTEL_RESP', payload: data })
    checkResponseMessage(true, "Sukses", "Sukses menghapus Hotel")
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setSearchAdmin = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/v1/admin`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_ADMIN', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllProvince = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      "ngrok-skip-browser-warning": "true" ,
      'Accept': '*/*',
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/address/province`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_PROVINCE', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllCity = async (dispatch, province_id) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      "ngrok-skip-browser-warning": "true" ,
      'Accept': '*/*',
    },
    url     : `${process.env.REACT_APP_API_URI}/address/city?province_id=${province_id}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_CITY', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response)
  })
}

export const resetDeleteAdminHotelResp = async (dispatch) => {
  dispatch({ type: 'RESET_DELETE_ADMIN_RESP' })
}

export const resetDeleteHotelResp = async (dispatch) => {
  dispatch({ type: 'RESET_DELETE_HOTEL_RESP' })
}

export const resetCreateHotel = async (dispatch) => {
  dispatch({ type: 'RESET_CREATE_HOTEL' })
}
