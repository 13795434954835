import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { setReservationById, setUpdateDetailReservation, setUpdateStatusReservation } from '../store/actions/reservationAction'
import { setAllProperty } from '../store/actions/propertyAction'

const ReservationDetailPage = ({ dispatch, dataReservation, dataProperty }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { reservationId } = useParams()
  const [id, setId] = useState("");

  const [status, setStatus] = useState("");
  const [oldStatus, setOldStatus] = useState("");
  const [availStatus, setAvailStatus] = useState([
    {id: 1, name:"Arrival"},
    {id: 2, name:"Check In"}, {id: 3, name:"Check Out"},
    {id: 4, name:"No Show"}, {id: 5, name:"Cancelled"},
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const [name, setName] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");

  const [roomType, setRoomType] = useState("");
  const [availRoom, setAvailRoom] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [totalGuest, setTotalGuest] = useState("");
  const [bookingSource, setBookingSource] = useState("");
  const [price, setPrice] = useState("");

  // non mandatory 
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [KTP, setKTP] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [notes, setNotes] = useState("");

  const [allAvailBookingSource, setAllAvailBookingSource] = useState([
    {id: 0, name:"Direct Booking"},
    {id: 1, name:"Walk In Guest"},
    {id: 2, name:"OTA Traveloka"},
    {id: 3, name:"OTA Tiket.com"},
    {id: 4, name:"OTA Agoda"},
    {id: 5, name:"OTA Booking.com"},
    {id: 6, name:"OTA C-TRIP"},
    {id: 7, name:"OTA Expedia"},
    {id: 8, name:"OTA KlikandBook"},
    {id: 9, name:"OTA MG Holiday"},
    {id: 10, name:"OTA ZUZU"},
    {id: 11, name:"OTA RedDoorz"},
  ]);
  
  const [allAvailPaymentMethod, setAllAvailPaymentMethod] = useState([
    {id: 1, name:"Cash"},
    {id: 2, name:"Debit Card"},
    {id: 3, name:"Credit Card"},
    {id: 4, name:"Qris"},
    {id: 5, name:"City Ledger to NamRoom.id"},
    {id: 6, name:"City Ledger to Zuzu"},
    {id: 7, name:"City Ledger to Reddorz"},
    {id: 8, name:"Staff Ledger"},
  ]);

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const doUpdate = (e) => {
    e.preventDefault()
    if(oldStatus== '1' && status.id =='3'){
      Swal.fire({
        title: 'Perhatian',
        html: `Perubahan Status Reservasi dari "Arrival" ke "Check Out" harus melewati "Check In" terlebih dahulu. <br><br> Mohon update ke "Check In"`,
        icon: 'warning',
        confirmButtonColor: '#1b4460',
      })
    }else{
      const dataUpdate = {
        email,
        status: status.id,
        name: name,
        start_date: Math.floor(checkin),
        end_date: Math.floor(checkout),
        guests: totalGuest,
        property_id: selectedRoom.id,
        notes,
        country,
        id_card: KTP,
        room_number: roomNumber,
        address,
        price,
        birth_date: Math.floor(birthDate),
        payment_method: paymentMethod.name,
        booking_source: bookingSource.name,
        phone,
      }
      setUpdateDetailReservation(dispatch, dataUpdate, reservationId)
      setOldStatus(status.id)
    }
  }

  const adjustDataFormat = (data, section) => {
    if( section === "province "){
      setProvince(data)
    }else{
      setCity(data)
    }
  }
  
  const handleSelect = (e, type) => {
    const splitValue = e.target.value.split("||")
    if( type === 'status' ){
      setStatus({id: splitValue[0], name: splitValue[1]}) 
    }else if( type === 'room' ){
      setSelectedRoom({id: splitValue[0], type: splitValue[1]}) 
    }else if( type === "Booking Source"){
      setBookingSource({id: splitValue[0], name: splitValue[1]})
    }else if(type === "Metode Pembayaran") {
      setPaymentMethod({id: splitValue[0], name: splitValue[1]}) 
    }
  }

  const handleRoomType = (allRoom) => {
    let result = []
    for( let i=0 ; i<allRoom.length ; i++ ){
      let oneRoom = {}
      oneRoom['id'] = allRoom[i].id
      oneRoom['name'] = allRoom[i].type
      if(oneRoom.id == roomType){
        oneRoom['selected'] = true;
      }else{
        oneRoom['selected'] = false;
      }
      result.push(oneRoom)
    }
    setAvailRoom(result)
    setIsLoading(false)
  }

  const handleStatus = (statusId) => {
    let result = []
    for( let i=0 ; i<availStatus.length ; i++ ){
      let oneStatus = {}
      oneStatus['id'] = availStatus[i].id
      oneStatus['name'] = availStatus[i].name
      if(oneStatus.id == statusId){
        oneStatus['selected'] = true;
        setStatus({id: oneStatus.id, name: oneStatus.name}) 
      }else{
        oneStatus['selected'] = false;
      }
      result.push(oneStatus)
    }
    setAvailStatus(result)
    setIsLoading(false)
  }

  useEffect(()=>{
    if( dataReservation.allProvinceResp ){
      let data = dataReservation.allProvinceResp
      adjustDataFormat(data, "province")
    }
  },[dataReservation.allProvinceResp])

  useEffect(()=>{
    if( dataReservation.allCityResp ){
      let data = dataReservation.allCityResp
      adjustDataFormat(data, "city")
    }
  },[dataReservation.allCityResp])

  useEffect(()=>{
    if( dataReservation.reservationPassResp ){
      Swal.fire({
        title: 'Success',
        text: 'Success Change Password',
        icon: 'Success',
        confirmButtonColor: '#1b4460',
      })
    }
  },[dataReservation.reservationPassResp])

  useEffect(()=>{
    if( dataProperty.propertyResp ){
      handleRoomType(dataProperty.propertyResp.data)
    }
  },[dataProperty.propertyResp])
  
  useEffect(()=>{
    if( dataReservation.reservationDetailResp ){
      let data = dataReservation.reservationDetailResp
      
      setId(data.id)
      handleStatus(data.status)
      setOldStatus(data.status)
      setRoomType(data.property?.type || "")
      setHotelName(data.hotel_name)
      setName(data.guest_name)
      setEmail(data.email)
      setPhone(data.phone)
      setRoomNumber(data.room_number)
      setIsLoading(false)
      setCheckin(Math.floor(new Date(data.start_date).getTime()))
      setCheckout(Math.floor(new Date(data.end_date).getTime()))
      setTotalGuest(data.guests)
      setNotes(data.notes)
      setPrice(data.price)
      setAllProperty(dispatch,0, data.hotel_id)
      setCountry(data.country)
      setKTP(data.id_card)
      setAddress(data.address)
      setBirthDate(Math.floor(new Date(data.birth_date).getTime()))
      setBookingSource({id: 0, name:data.booking_source})
      setPaymentMethod({id: 0, name: data.payment_method})
      localStorage.setItem("dataBooking",JSON.stringify(data))
      if( localStorage.getItem('role') !== 'AFFILIATOR' ){
        setRoomNumber(data.room_number)
      }

    }
  },[dataReservation.reservationDetailResp])

  useEffect(()=>{
    setReservationById(dispatch, reservationId)
    setId(reservationId)
  },[dispatch, reservationId])

  const dataForm = [
    {
      label: "Update Status",
      type: "dropdown",
      section: "status",
      placeholder: "Pilih Status",
      spaceMd: "6",
      spaceXs: "6",
      value: status,
      action: handleSelect,
      required: true,
      dataDropdown: availStatus,
    },{
      label: "Update Status",
      type: "button_submit_2",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama*",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: name,
      action: setName,
    },{
      label: "No KTP",
      type: "text",
      placeholder: "Masukkan No KTP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: KTP,
      action: setKTP,
    },{
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Tanggal Lahir",
      type: "dateAdd",
      placeholder: "Masukkan Tanggal Lahir",
      spaceMd: "6",
      spaceXs: "12",
      availability:true,
      value: birthDate,
      action: setBirthDate,
      required: true,
    },{
      label: "Negara",
      type: "text",
      placeholder: "Masukkan Negara",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: country,
      action: setCountry,
    },{
      label: "Alamat",
      type: "text",
      placeholder: "Masukkan Alamat",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Check in (tidak dapat diubah)",
      type: "text",
      placeholder: "Masukkan Check in",
      spaceMd: "6",
      spaceXs: "12",
      // availability:true,
      value: new Date(checkin).toLocaleDateString('en-GB', {day: '2-digit',month: '2-digit',year: '2-digit'}),
      notEditable: true,
      // action: setCheckin,
      // required: true,
    },{
      label: "Check out (tidak dapat diubah)",
      type: "text",
      placeholder: "Masukkan Check out",
      spaceMd: "6",
      spaceXs: "12",
      // availability:true,
      value: new Date(checkout).toLocaleDateString('en-GB', {day: '2-digit',month: '2-digit',year: '2-digit'}),
      notEditable: true,
      // action: setCheckout,
      // required: true,
    },{
      label: "Hotel (tidak dapat diubah)",
      type: "text",
      placeholder: "Masukkan Hotel",
      spaceMd: "6",
      notEditable: true,
      spaceXs: "12",
      required: true,
      value: hotelName,
      action: setHotelName,
    },{
      label: "Tipe Kamar (tidak dapat diubah)",
      type: "text",
      section: "room",
      placeholder: "Masukkan Tipe Kamar",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      value: roomType,
    // },{
    //   label: "Tipe Kamar (tidak dapat diubah)",
    //   type: "dropdown",
    //   section: "room",
    //   placeholder: "Masukkan Tipe Kamar",
    //   spaceMd: "6",
    //   spaceXs: "12",
    //   required: true,
    //   action: handleSelect,
    //   value: selectedRoom,
    //   dataDropdown: availRoom,
    },{
      label: "Nomor Kamar (tidak dapat diubah)",
      type: "text",
      section: "room",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      value: localStorage.getItem("role") == "AFFILIATOR" ? "Dipilih oleh sistem" :    roomNumber,
    },{
      label: "Jumlah tamu*",
      type: "text",
      placeholder: "Masukkan Jumla Tamu",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: totalGuest,
      action: setTotalGuest,
    },{
      label: "Metode Pembayaran*",
      section: "Metode Pembayaran",
      type: "dropdown",
      placeholder: "Masukkan Metode Pembayaran",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      action: handleSelect,
      required: true,
      value: paymentMethod,
      dataDropdown: allAvailPaymentMethod,
    },{
      label: "Booking Source*",
      section: "Booking Source",
      type: "dropdown",
      placeholder: "Masukkan Booking Source",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: bookingSource,
      dataDropdown: allAvailBookingSource,
    },{
      label: "Catatan",
      type: "text",
      placeholder: "Masukkan Catatan",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: notes,
      action: setNotes,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Harga Total* (Rupiah)",
      type: "text",
      placeholder: "Masukkan Harga Total",
      spaceMd: "6",
      spaceXs: "12",
      currency: true,
      required: true,
      value: price,
      action: setPrice,
    },{
      type: "SPACE",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../reservation',
      spaceXs: "3",
    },{
      label: "Cetak Invoice",
      type: "buttonInvoice",
      spaceMd: "3",
      link: `../reservationDetail/${reservationId}/invoice`,
      spaceXs: "3",
    },
  ]

  return (    
    isLoading === false &&
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Reservation"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        id={id}
        pageFor={"detail"}
        email={email}
        onSubmit={doUpdate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataReservation: state.reservation,
    dataProperty: state.property,
  };
};

export default connect(
  storage
)(ReservationDetailPage)
