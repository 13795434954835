const initialState = {
  allAfiliatorResp: null,
  createAfiliatorResp: null,
  afiliatorHotelDetailResp: null,
  afiliatorDeleteResp: null,

  afiliatorHotelListResp: null,
  afiliatorMyselfDetailResp: null,
  afiliatorDetailUpdateResp: null,
  allProvinceResp: null,
  allCityResp: null,
  afiliatorPassResp: null,
  afiliatorSelfUpdatePassResp: null,
  afiliatorSelfUpdateDetailResp: null,
  hotelDetailResp: null,
  allHotelResp: null,
  createHotelResp: null, 
  hotelDetailUpdateResp: null,
  hotelAfiliatorUpdateResp: null,
  hotelDeleteResp: null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_AFILIATOR': {
      return {...state, allAfiliatorResp: action.payload}
    }
    case 'SET_CREATE_AFILIATOR': {
      return {...state, createAfiliatorResp: action.payload}
    }
    case 'SET_AFILIATOR_DETAIL_RESP':{
      return {...state, afiliatorHotelDetailResp: action.payload}
    }
    case 'SET_UPDATE_AFILIATOR_RESP':{
      return {...state, hotelAfiliatorUpdateResp: action.payload.data}
    }
    case 'SET_DELETE_AFILIATOR_RESP':{
      return {...state, afiliatorDeleteResp: action}
    }
    case 'RESET_DELETE_AFILIATOR_RESP':{
      return {...state, afiliatorDeleteResp: null}
    }
    default:
      return state
  }
}

export default reducer
