const initialState = {
  dashboardOverviewPerHotelResp:null,
  dasboardStatisticPerHotelResp: null,
  dashboardRegionOverview: null,
  dashboardSourceOverviewResp: null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DASHBOARD_OVERVIEW_PER_HOTEL': {
      return {...state, dashboardOverviewPerHotelResp: action.payload}
    }
    case 'SET_DASHBOARD_REGION_OVERVIEW':{
      return {...state, dashboardRegionOverview: action.payload}
    }
    case 'SET_DASHBOARD_STATISTIC_PER_HOTEL': {
      return {...state, dasboardStatisticPerHotelResp: action.payload}
    }
    case 'SET_DASHBOARD_SOURCE_OVERVIEW': {
      return {...state, dashboardSourceOverviewResp: action.payload}
    }
    default:
      return state
  }
}

export default reducer
