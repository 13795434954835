import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Form, InputGroup, } from 'react-bootstrap';
import 'rsuite/dist/rsuite.min.css';
import { BiSearchAlt } from 'react-icons/bi'
import { Link } from "react-router-dom";
import styles from './BaseTable.module.scss';
import BaseTable from "./BaseTable";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { setAllAdmin } from '../../store/actions/adminAction'

const AllAvailRoomTable = ({
  pageName,
  dispatch, 
  dataAdmin,
  dataRooms,
  pagination,
  setPagination,
  action,
  activePage,
  handlePageChange,
}) => {

  const [status, setStatus] = useState(0);
  // const [activePage, setActivePage] = useState(1)
  const [data, setData] = useState([])
  // const [pagination, setPagination] = useState({})
  const [searchKeyword, setSearchKeyword] = useState(null)

  const doSearch = (e) => {
    e.preventDefault()
    let params = {}
    if( searchKeyword ){
      params['search'] = searchKeyword
    }
    if( status ){
      params['status'] = status
    }
    setAllAdmin(dispatch, 0, params)
  }
 
  const doClearFilter = (e) => {
    let params = {search: ""}
   
    setSearchKeyword("")
    setAllAdmin(dispatch, 0, params)
  }

  const setDataShown = (datas) => {
    let listData = []
    for (let idx in datas) {
      if( localStorage.getItem('role') === "AFFILIATOR" ){
        listData.push({
          'ID': datas[idx].id + "-" +datas[idx]['property Id'],
          'Tipe Kamar': datas[idx]['Tipe Kamar'],
          'Kapasitas Kamar': datas[idx]['Kapasitas Kamar'] + " Orang",
          'Nomor Kamar': datas[idx]['Nomor Kamar'],
          // 'Harga': datas[idx].price,
          'Ketersediaan': datas[idx].available,
        })
      }else{
        listData.push({
          'ID': datas[idx].id + "-" +datas[idx]['property Id'],
          'Tipe Kamar': datas[idx]['Tipe Kamar'],
          'Nomor Kamar': datas[idx]['Nomor Kamar'],
          'Kapasitas Kamar': datas[idx]['Kapasitas Kamar'] + " Orang",
          // 'Harga': datas[idx].price,
          'Ketersediaan': datas[idx].available,
        })
      }
    }
    setData(listData)
  }

	useEffect(()=>{
    setAllAdmin(dispatch, 0)
	},[dispatch])

  useEffect(()=>{
    // if( dataRooms.length > 0 ){
      setDataShown(dataRooms)
      setPagination({
        offset: pagination.offset, 
        limit: pagination.limit, 
        total: pagination.total, 
      })
    // }
  },[dataRooms])

	return (
    <>
      <Container className={styles.container}>
        <Row>
          {/* filter commented for future usage  */}
          {/* <Col xs="3">
            <Form.Label htmlFor="basic-url">Search</Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon2" className={styles.icon_search}>
                {<BiSearchAlt/>}
              </InputGroup.Text>
              <Form.Control 
                className={styles.field_search}
                type={"text"} 
                placeholder={"Search"}
                onChange={(e)=>setSearchKeyword(e.target.value)}
                value={searchKeyword}
              />
            </InputGroup >
          </Col>
          <Col xs="3">
            <Form.Label htmlFor="basic-url">Filter by Status</Form.Label>
            <Form.Select aria-label="Choose Status" className={styles.field_form} 
              onChange={ (e)=> setStatus(e.target.value)}
            >
              <option>{"Select Status"}</option>
              <option>{"Active"}</option>
              <option>{"Inactive"}</option>
            </Form.Select>
          </Col>
          <Col xs="3" className="mt-4">
            <Button className={styles.save_button} onClick={(e)=>doSearch(e)}>
              {"Apply"}
            </Button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Button className={styles.cancel_button} onClick={(e)=>doClearFilter(e)} >
              {"Cancel"}
            </Button>
          </Col> */}
          <Col xs={{ span:3}} className="mt-4">
            <p className={styles.main_title}>
              {pageName}
            </p>
          </Col>
        </Row>
        {data.length > 0 ?
          <BaseTable 
            data={data} 
            linkDetail={"../adminDetail/"} 
            pagination={pagination}
            section={"allAvailRoom"}
            activePage={activePage}
            action={action}
            handlePageChange={handlePageChange}
          />
          :
          <>
            <br/>
            <br/>
            <p>
              Tidak ada kamar tersedia di tanggal yang dipilih ..
            </p>
          </>
        }
      </Container>
    </>
	);
};

const storage = state => {
  return {
    dataAdmin: state.admin,
  };
};

export default connect(
  storage
)(AllAvailRoomTable)  