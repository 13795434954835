import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setAllAfiliator = async (dispatch, page, filter) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : filter ,
    url     : `${process.env.REACT_APP_API_URI}/admin?role=AFFILIATOR&page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_AFILIATOR', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setCreateAfiliator = async (dispatch, data) => {
  axios.post(`${process.env.REACT_APP_API_URI}/admin/v2`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_CREATE_AFILIATOR', payload: data })
    checkResponseMessage(true, "Sukses", "Berhasil menambahkan Afiliator baru")
    // window.location.reload();
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setUpdateDetailAfiliator = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/afiliator/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_AFILIATOR_RESP', payload: data })
    checkResponseMessage(true, "Success", "Afiliator Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateDetailHotel = async (dispatch, data, id) => {
  console.log(data, id)
  axios.put(`${process.env.REACT_APP_API_URI}/admin/v2/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_AFILIATOR_RESP', payload: data })
    checkResponseMessage(true, "Success", "Afiliator Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setHotelAfiliatorListById = async (dispatch, hotelId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/afiliator?hotel_id=${hotelId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_AFILIATOR_LIST_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setHotelAfiliatorDetailById = async (dispatch, afiliatorId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/admin/${afiliatorId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_AFILIATOR_DETAIL_RESP', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateHotelAfiliatorDetail = async (dispatch, data, afiliatorId) => {
  axios.put(`${process.env.REACT_APP_API_URI}/afiliator/v2/${afiliatorId}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_HOTEL_AFILIATOR_RESP', payload: data })
    checkResponseMessage(true, "Success", "Afiliator Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setDeleteAfiliatorById = async (dispatch, afiliatorId) => {
  axios.delete(`${process.env.REACT_APP_API_URI}/admin/v2/${afiliatorId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_DELETE_AFILIATOR_RESP', payload: data })
    checkResponseMessage(true, "Sukses", "Sukses menghapus Afiliator Hotel")
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setSearchAfiliator = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/v1/afiliator`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_AFILIATOR', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const resetDeleteAfiliatorHotelResp = async (dispatch) => {
  dispatch({ type: 'RESET_DELETE_AFILIATOR_RESP' })
}

export const resetDeleteHotelResp = async (dispatch) => {
  dispatch({ type: 'RESET_DELETE_HOTEL_RESP' })
}

export const resetCreateHotel = async (dispatch) => {
  dispatch({ type: 'RESET_CREATE_HOTEL' })
}
