import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { checkResponseMessage } from "../helper/helper";
import { setOneRoom, setUpdateDetailRoom, setDeletePropertyById } from '../store/actions/propertyAction'
import { setAllHotel } from '../store/actions/hotelAction'
import Swal from 'sweetalert2';

const RoomDetailPage = ({ dispatch, dataProperty, dataHotel }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { propertyId, roomId } = useParams()
  const [hotelId, seHotelId] = useState("");

  const [roomNumber, setRoomNumber] = useState("");
  const [isActive, setIsActive] = useState("");

  const doUpdate = (e) => {
    e.preventDefault()
    const dataUpdate = {
      room_number: roomNumber,
      active: isActive.id
    }
    setUpdateDetailRoom(dispatch, dataUpdate, propertyId, roomId)
  }

  const backPage = () => {
    navigate(-1)
  }

  const doDelete = async (e) => {
    e.preventDefault()
    const needConfirmation = await checkResponseMessage(false, "Menghapus property", "Apakah anda yakin untuk menghapus property hotel ini?", true)
    if(needConfirmation){
      setDeletePropertyById(dispatch, propertyId)
    }
  }

  // const setUpdateDetailRoom = (e) => {
  //   e.preventDefault()
  //   if(roomDatas.length === 0){
  //     Swal.fire({
  //       title: 'Warning',
  //       text: 'Masukkan setidaknya 1 kamar untuk tipe kamar ini',
  //       icon: 'warning',
  //       confirmButtonColor: '#1b4460',
  //     })
  //   }else{
  //     const name = localStorage.getItem('hotelId') || selectedHotel.id
  //     const dataCreate = {
  //       hotel_id: name,
  //       type: roomType,
  //       capacity: roomCapacity,
  //       rooms: totalRoom,
  //       rooms_data: roomDatas
  //     }
  //     setCreateProperty(dispatch, dataCreate)
  //   }
  // }
  
  const handleSelect = (e) => {
    const splitValue = e.target.value.split("||")
    setIsActive({id: splitValue[0], name: splitValue[1]}) 
  }
  
  useEffect(()=>{
    if( dataProperty.roomDetailResp ){
      let data = dataProperty.roomDetailResp
      seHotelId(data.hotel_id)
      setRoomNumber(data.room_number)
      setIsActive(data.active == 1 ? {name: "aktif"} : {name:"tidak aktif"})
      setIsLoading(false)
    }
  },[dataProperty.roomDetailResp])

  useEffect(()=>{
    setOneRoom(dispatch, propertyId, roomId)
    seHotelId(propertyId)
  },[dispatch, propertyId, roomId])

  useEffect(()=>{
    if( dataProperty.createPropertyResp ){
    }
  },[dataProperty.createPropertyResp])

  const dataForm = [
    {
      label: "Nomor Kamar",
      type: "text",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomNumber,
      action: setRoomNumber,
    },{
      label: "Status Kamar",
      type: "dropdown",
      section: "status",
      placeholder: "Masukkan Status Kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: isActive,
      action: handleSelect,
      dataDropdown:[{id: 1, name:"aktif"},{id: 0, name:"tidak aktif"}]
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      onClickAction: backPage,
      spaceXs: "3",
    }
  ]

  const dataFormSuperAdmin = [
    {
      label: "Nomor Kamar",
      type: "text",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomNumber,
      action: setRoomNumber,
    },{
      label: "Status Kamar",
      type: "dropdown",
      section: "status",
      placeholder: "Masukkan Status Kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: isActive,
      action: handleSelect,
      dataDropdown:[{id: 1, name:"aktif"},{id: 0, name:"tidak aktif"}]
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      onClickAction: backPage,
      spaceXs: "3",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Kamar"}
        dataForm={localStorage.getItem('is_super_admin') === 'true' ? dataFormSuperAdmin : dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        onSubmit={setUpdateDetailRoom}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataProperty: state.property,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(RoomDetailPage)
