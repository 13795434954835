import axios from 'axios';
import { checkResponseMessage } from '../../helper/helper'

export const setAllReservation = async (dispatch, filter, page) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : filter ,
    // url     : `${process.env.REACT_APP_API_URI}/reservation`,
    url     : `${process.env.REACT_APP_API_URI}/reservation?order=desc&page=${page}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_RESERVATION', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setAllReservationReporting = async (dispatch, filter, page) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
    params  : filter ,
    // url     : `${process.env.REACT_APP_API_URI}/reservation`,
    url     : `${process.env.REACT_APP_API_URI}/reservation?size=${99999}`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_RESERVATION_REPORT', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setCreateReservation = async (dispatch, data) => {
  axios.post(`${process.env.REACT_APP_API_URI}/reservation`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_CREATE_RESERVATION', payload: data })
    checkResponseMessage(true, "Success", "Reservation Added Successfully")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setUpdatePassReservation = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/reservation/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_PASS_RESERVATION_RESP', payload: data })
    checkResponseMessage(true, "Success", "Reservation Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setUpdateDetailReservation = async (dispatch, data, id) => {
  axios.put(`${process.env.REACT_APP_API_URI}/reservation/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_RESERVATION_RESP', payload: data })
    checkResponseMessage(true, "Success", "Reservation Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.error)
  })
}

export const setUpdateStatusReservation = async (dispatch, data, id) => {
  axios.patch(`${process.env.REACT_APP_API_URI}/reservation/${id}`, data, {
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_UPDATE_RESERVATION_RESP', payload: data })
    checkResponseMessage(true, "Success", "Reservation Information Updated")
    setTimeout(() => { 
      window.location.reload(false);
    }, 1500)
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setReservationById = async (dispatch, reservationId) => {
  axios.get(`${process.env.REACT_APP_API_URI}/reservation/${reservationId}`,{
    headers: {
      "Authorization": localStorage.getItem('token'),
      "ngrok-skip-browser-warning": "true" ,
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "*/*",
    },
  },{
  }).then(({data}) => {
    dispatch({ type: 'SET_RESERVATION_DETAIL_RESP', payload: data.data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error)
  })
}

export const setSearchReservation = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/v1/reservation`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_RESERVATION', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllProvince = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/v1/province`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_PROVINCE', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const setAllCity = async (dispatch, params) => {
  const options = {
    method  : 'get',
    headers : {
      'Authorization': localStorage.getItem('token'),
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Accept': '*/*',
    },
    params  : params,
    url     : `${process.env.REACT_APP_API_URI}/v1/city`,
  }

  axios(options).then(({data}) => {
    dispatch({ type: 'SET_ALL_CITY', payload: data })
  }).catch((error)=>{
    checkResponseMessage(false, "Error", error.response.data.message)
  })
}

export const resetCreateReservation = async (dispatch) => {
  dispatch({ type: 'RESET_CREATE_RESERVATION' })
}
