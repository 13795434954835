import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { 
  setHotelAdminListById, 
  setUpdateDetailAdmin, 
  setAllProvince, 
  setAllCity, 
  setCreateAdmin,
  setDeleteHotelById, 
  setUpdatePassAdmin,
  setHotelById, 
  setUpdateDetailHotel,
  resetDeleteHotelResp,
} from '../store/actions/adminAction'
import { checkResponseMessage } from "../helper/helper";
import { useNavigate } from 'react-router-dom';

const AdminDetailPage = ({ dispatch, dataAdmin }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { hotelId } = useParams()
  const [id, setId] = useState("");
  const [adminValue, setAdminValue] = useState({
    nama: "",
    email: "",
    password: "",
    confPassword: "",
  });
  const [pagination, setPagination] = useState({})

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [availProvince, setAvailProvince] = useState([]);
  const [availCity, setAvailCity] = useState([]);
  const [dataAllAdmin, setDataAllAdmin] = useState([]);

  const doUpdate = (e) => {
    e.preventDefault()
    const dataUpdate = {
      id,
      name: hotelName,
      address,
      phone,
      province: selectedProvince.name,
      city: selectedCity.name,
    }
    setUpdateDetailHotel(dispatch, dataUpdate, id)
  }

  const doDelete = async (e) => {
    e.preventDefault()
    const needConfirmation = await checkResponseMessage(false, "Menghapus admin", "Apakah anda yakin untuk menghapus admin hotel ini?", true)
    if(needConfirmation){
      setDeleteHotelById(dispatch, id)
    }
  }

  const doChangePassUpdate = (e) => {
    e.preventDefault()
    if(confirmNewPass !== newPassword){
      Swal.fire({
        title: 'Warning',
        text: 'Password baru dan konfirmasi password harus sama',
        icon: 'warning',
        confirmButtonColor: '#1b4460',
      })
    }else{
      const dataChangePass = {
        id,
        confirm_password: confirmNewPass,
        password: newPassword,
      }
      setUpdatePassAdmin(dispatch, dataChangePass, id)
    }
  }

  const adminValueHandle = (value, section) =>{
    const curr = {...adminValue}
    if(section === "nama"){
      curr.nama = value
    }else if(section === "email"){
      curr.email = value
    }else if(section === "password"){
      curr.password = value
    }else if(section === "confPass"){
      curr.confPass = value
    }
    setAdminValue(curr)
  }

  const addAdminAction = (e) => {
    e.preventDefault()
    const dataAdmin = {
      name: adminValue.nama,
      email: adminValue.email,
      password: adminValue.password,
      confirm_password: adminValue.confPass,
      hotel_id: hotelId,
    }
    setCreateAdmin(dispatch, dataAdmin, hotelId)
  }
  
  const handleSelect = (e, type) => {
    const splitValue = e.target.value.split("||")
    if( type === "Provinsi" ){
      setSelectedProvince({id: splitValue[0], name: splitValue[1]}) 
    }else{
      setSelectedCity({id: splitValue[0], name: splitValue[1]}) 
    }
  }

  const adjustDataFormat = (data, section) => {
    if( section === "province"){
      setAvailProvince(data)
    }else{
      setAvailCity(data)
    }
  }

  useEffect(()=>{
    if( dataAdmin.hotelDeleteResp ){
      resetDeleteHotelResp(dispatch)
      navigate(-1);
    }
  },[dataAdmin.hotelDeleteResp])

  useEffect(()=>{
    if( dataAdmin.allProvinceResp ){
      let data = dataAdmin.allProvinceResp
      adjustDataFormat(data, "province")
      setAllCity(dispatch, data[0].id)
    }
  },[dataAdmin.allProvinceResp])

  useEffect(()=>{
    if( dataAdmin.allCityResp ){
      let data = dataAdmin.allCityResp
      adjustDataFormat(data, "city")
      setIsLoading(false)
    }
  },[dataAdmin.allCityResp])

  useEffect(()=>{
    if( dataAdmin.adminPassResp ){
      Swal.fire({
        title: 'Success',
        text: 'Success Change Password',
        icon: 'Success',
        confirmButtonColor: '#1b4460',
      })
    }
  },[dataAdmin.adminPassResp])

  useEffect(()=>{
    if(selectedProvince.id){
      setAllCity(dispatch, selectedProvince.id)
    }
  },[selectedProvince])

  useEffect(()=>{
    if( dataAdmin.adminDeleteResp ){
      navigate('/adminManagement');
    }
  },[dataAdmin.adminDeleteResp])

  useEffect(()=>{
    setAllProvince(dispatch)
  },[])

  const setDataShown = (datas) => {
    let listData = []
    for (let idx in datas) {
      listData.push({
        'ID': datas[idx].id,
        'Nama': datas[idx].name,
        'Email': datas[idx].email,
      })
    }
    setDataAllAdmin(listData)
  }
  
  useEffect(()=>{
    if( dataAdmin.hotelDetailResp ){
      let data = dataAdmin.hotelDetailResp
      setId(data.id)
      setEmail(data.email)
      if(email !== 'superadmin@gmail.com'){
        setHotelName(data.name)
        setAddress(data.address)
        setPhone(data.phone)
        localStorage.setItem("hotelName",data.name)
        setSelectedProvince({id: data.province_id, name: data.province}) 
        setSelectedCity({id: data.city_id, name: data.city}) 
      }
    }
  },[dataAdmin.hotelDetailResp])
  
  useEffect(()=>{
    if( dataAdmin.adminHotelListResp ){
      setDataShown(dataAdmin.adminHotelListResp.data)
      setPagination({
        offset: dataAdmin.adminHotelListResp.total_pages, 
        limit: 10, 
        total: dataAdmin.adminHotelListResp.total, 
      })
      let data = dataAdmin.adminHotelListResp.data
      setIsLoading(false)
    }
  },[dataAdmin.adminHotelListResp])

  useEffect(()=>{
    setHotelAdminListById(dispatch, hotelId)
    setHotelById(dispatch, hotelId)
    setId(hotelId)
  },[dispatch, hotelId])
 
  const dataForm = [
    {
      label: "Nama Hotel",
      type: "text",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: hotelName,
      action: setHotelName,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Alamat Hotel",
      type: "text",
      placeholder: "Masukkan Alamat Hotel",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Pilih Provinsi",
      section: "Provinsi",
      type: "dropdown",
      section: "Provinsi",
      placeholder: "Pilih Provinsi",
      spaceMd: "6",
      spaceXs: "6",
      value: selectedProvince,
      action: handleSelect,
      required: true,
      dataDropdown:availProvince
    },{
      label: "Pilih Kota",
      section: "Kota",
      type: "dropdown",
      section: "Kota",
      placeholder: "Pilih Kota",
      spaceMd: "6",
      spaceXs: "6",
      value: selectedCity,
      action: handleSelect,
      required: true,
      dataDropdown: availCity,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "12",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../adminManagement',
      spaceXs: "3",
    },{
      label: "Hapus",
      type: "buttonDelete",
      spaceMd: "3",
      spaceXs: "3",
      action: doDelete,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "List admin",
      type: "tableAdmin",
      placeholder: "List admin",
      spaceMd: "12",
      spaceXs: "12",
      value: dataAllAdmin,
      action: addAdminAction,
      valueAdmin: adminValue,
      actionOnChange : adminValueHandle,
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Hotel"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        id={id}
        pageFor={"detail"}
        email={email}
        onSubmit={doUpdate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataAdmin: state.admin,
  };
};

export default connect(
  storage
)(AdminDetailPage)
