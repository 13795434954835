import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { 
  setHotelAdminDetailById,
  setUpdateHotelAdminDetail,
  setUpdatePassAdmin,
  setDeleteAdminById,
  resetDeleteAdminHotelResp,
} from '../store/actions/adminAction'
import { checkResponseMessage } from "../helper/helper";
import { useNavigate } from 'react-router-dom';

const AdminHotelDetailPage = ({ dispatch, dataAdmin }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { adminId } = useParams()
  const [id, setId] = useState("");
  const [adminValue, setAdminValue] = useState({
    nama: "",
    email: "",
    password: "",
    confPassword: "",
  });
  const [pagination, setPagination] = useState({})

  const [adminName, setAdminName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [password, setPassword] = useState("");
  const [confPass, setConfPass] = useState("");

  const doUpdateDetails = (e) => {
    e.preventDefault()
    const dataUpdate = {
      name: adminName,
      email: email,
    }
    setUpdateHotelAdminDetail(dispatch, dataUpdate, adminId)
  }

  const doDeleteAdmin = async (e) => {
    e.preventDefault()
    const needConfirmation = await checkResponseMessage(false, "Menghapus admin", "Apakah anda yakin untuk menghapus admin hotel ini?", true)
    if(needConfirmation){
      setDeleteAdminById(dispatch, id)
    }
  }

  const doChangePassUpdate = (e) => {
    e.preventDefault()
    if(confPass !== password){
      Swal.fire({
        title: 'Warning',
        text: 'Password baru dan konfirmasi password harus sama',
        icon: 'warning',
        confirmButtonColor: '#1b4460',
      })
    }else{
      const dataChangePass = {
        id,
        confirm_password: confPass,
        password: password,
      }
      setUpdatePassAdmin(dispatch, dataChangePass, id)
    }
  }

  const backButton = () => {
    navigate(-1);
  }

  useEffect(()=>{
    if( dataAdmin.adminPassResp ){
      Swal.fire({
        title: 'Success',
        text: 'Success Change Password',
        icon: 'Success',
        confirmButtonColor: '#1b4460',
      })
    }
  },[dataAdmin.adminPassResp])

  useEffect(()=>{
    if( dataAdmin.adminDeleteResp ){
      resetDeleteAdminHotelResp(dispatch)
      navigate(-1);
    }
  },[dataAdmin.adminDeleteResp])
  
  useEffect(()=>{
    if( dataAdmin.adminHotelDetailResp ){
      let data = dataAdmin.adminHotelDetailResp.data
      setId(data.id)
      setEmail(data.email)
      setAdminName(data.name)
      setIsLoading(false)
    }
  },[dataAdmin.adminHotelDetailResp])

  useEffect(()=>{
    setHotelAdminDetailById(dispatch, adminId)
    setId(adminId)
  },[dispatch, adminId])
 
  const dataForm = [
    {
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email ...",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Admin Name",
      type: "text",
      placeholder: "Masukkan Admin Name ...",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: adminName,
      action: setAdminName,
    },{
      label: "Update",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdateDetails,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "New Password",
      type: "password",
      section: "Provinsi",
      placeholder: "New Password ...",
      spaceMd: "6",
      spaceXs: "6",
      value: password,
      action: setPassword,
    },{
      label: "Confirm New Password",
      type: "password",
      section: "Provinsi",
      placeholder: "Confirm New Password ...",
      spaceMd: "6",
      spaceXs: "6",
      value: confPass,
      action: setConfPass,
    },{
      label: "Change Password",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doChangePassUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      onClickAction: backButton,
      spaceXs: "3",
    },{
      label: "Hapus Admin",
      type: "buttonDelete",
      spaceMd: "3",
      spaceXs: "3",
      action: doDeleteAdmin,
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Hotel Admin"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        id={id}
        pageFor={"detail"}
        email={email}
        onSubmit={doUpdateDetails}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataAdmin: state.admin,
  };
};

export default connect(
  storage
)(AdminHotelDetailPage)
