const initialState = {
  allAdminResp: null,
  adminHotelListResp: null,
  adminMyselfDetailResp: null,
  adminDetailUpdateResp: null,
  createAdminResp: null,
  allProvinceResp: null,
  allCityResp: null,
  adminPassResp: null,
  adminDeleteResp: null,
  adminSelfUpdatePassResp: null,
  adminSelfUpdateDetailResp: null,
  hotelDetailResp: null,
  allHotelResp: null,
  createHotelResp: null, 
  hotelDetailUpdateResp: null,
  adminHotelDetailResp: null,
  hotelAdminUpdateResp: null,
  hotelDeleteResp: null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_ADMIN': {
      return {...state, allAdminResp: action.payload}
    }
    case 'SET_ALL_HOTEL': {
      return {...state, allHotelResp: action.payload}
    }
    case 'SET_CREATE_ADMIN': {
      return {...state, createAdminResp: action.payload}
    }
    case 'SET_CREATE_HOTEL': {
      return {...state, createHotelResp: action.payload}
    }
    case 'SET_ADMIN_LIST_RESP':{
      return {...state, adminHotelListResp: action.payload}
    }
    case 'SET_ADMIN_DETAIL_RESP':{
      return {...state, adminHotelDetailResp: action.payload}
    }
    case 'SET_SELF_UPDATE_ADMIN_RESP':{
      return {...state, adminSelfUpdateDetailResp: action.payload.data}
    }
    case 'SET_UPDATE_HOTEL_ADMIN_RESP':{
      return {...state, hotelAdminUpdateResp: action.payload.data}
    }
    case 'SET_ADMIN_MYSELF_RESP':{
      return {...state, adminMyselfDetailResp: action.payload.data}
    }
    case 'SET_HOTEL_DETAIL_RESP':{
      return {...state, hotelDetailResp: action.payload.data}
    }
    case 'SET_UPDATE_PASS_ADMIN_RESP':{
      return {...state, adminPassResp: action.payload.data}
    }
    case 'SET_SELF_UPDATE_PASS_ADMIN_RESP':{
      return {...state, adminSelfUpdatePassResp: action.payload.data}
    }
    case 'SET_DELETE_ADMIN_RESP':{
      return {...state, adminDeleteResp: action}
    }
    case 'SET_DELETE_HOTEL_RESP':{
      return {...state, hotelDeleteResp: action}
    }
    case 'SET_UPDATE_ADMIN_RESP':{
      return {...state, adminDetailUpdateResp: action.payload}
    }
    case 'SET_UPDATE_HOTEL_RESP':{
      return {...state, hotelDetailUpdateResp: action.payload}
    }
    case 'SET_ALL_PROVINCE': {
      return {...state, allProvinceResp: action.payload}
    }
    case 'SET_ALL_CITY': {
      return {...state, allCityResp: action.payload}
    }
    case 'RESET_CREATE_ADMIN':{
      return {...state, createAdminResp: null}
    }
    case 'RESET_DELETE_ADMIN_RESP':{
      return {...state, adminDeleteResp: null}
    }
    case 'RESET_DELETE_HOTEL_RESP':{
      return {...state, hotelDeleteResp: null}
    }
    case 'RESET_CREATE_HOTEL': {
      return {...state, createHotelResp: null}
    }
    default:
      return state
  }
}

export default reducer
