import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { setHotelAfiliatorDetailById, setUpdateDetailHotel, setDeleteAfiliatorById, resetDeleteAfiliatorHotelResp } from '../store/actions/afiliatorAction'
import { checkResponseMessage } from "../helper/helper";
import { useNavigate } from 'react-router-dom';

const AfiliatorDetailPage = ({ dispatch, dataAfiliator }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { affId } = useParams()
  const [id, setId] = useState("");
  const [affiliatorCode, setAffiliatorCode] = useState("");
  const [adminValue, setAdminValue] = useState({
    nama: "",
    email: "",
    password: "",
    confPassword: "",
  });
  const [pagination, setPagination] = useState({})

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");

  const doUpdate = (e) => {
    e.preventDefault()
    const dataUpdate = {
      name,
      // password,
      // confirm_password: confirmPassword,
    }
    setUpdateDetailHotel(dispatch, dataUpdate, id)
  }

  const doUpdatePass = (e) => {
    e.preventDefault()
    const dataUpdate = {
      name,
      // password,
      // confirm_password: confirmPassword,
    }
    setUpdateDetailHotel(dispatch, dataUpdate, id)
  }

  const doDelete = async (e) => {
    e.preventDefault()
    const needConfirmation = await checkResponseMessage(false, "Menghapus Afiliator", "Apakah anda yakin untuk menghapus Afiliator ini?", true)
    if(needConfirmation){
      setDeleteAfiliatorById(dispatch, id)
    }
  }
  
  useEffect(()=>{
    if( dataAfiliator.afiliatorDeleteResp ){
      navigate('/afiliatorManagement');
      resetDeleteAfiliatorHotelResp(dispatch)
    }
  },[dataAfiliator.afiliatorDeleteResp])

  useEffect(()=>{
    if( dataAfiliator.afiliatorHotelDetailResp ){
      console.log(dataAfiliator.afiliatorHotelDetailResp, "< .afiliatorHotelDetailResp")
      let data = dataAfiliator.afiliatorHotelDetailResp.data
      setId(data.id)
      setEmail(data.email)
      setAffiliatorCode(data.affiliator_code)
      setName(data.name)
      setEmail(data.email)
    }
  },[dataAfiliator.afiliatorHotelDetailResp])

  useEffect(()=>{
    setHotelAfiliatorDetailById(dispatch, affId)
    setId(affId)
  },[dispatch, affId])
 
  const dataForm = [
    {
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      value: email,
      notEditable: true,
    },{
      label: "Afiliator Code",
      type: "text",
      placeholder: "Masukkan Afiliator Code",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: affiliatorCode,
      notEditable: true,
    },{
      label: "Nama",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      value: name,
      action: setName,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Update",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdate,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../afiliatorManagement',
      spaceXs: "3",
    },{
      label: "Hapus",
      type: "buttonDelete",
      spaceMd: "3",
      spaceXs: "3",
      action: doDelete,
    },{
    //   type: "SEPARATOR",
    //   spaceMd: "12",
    //   spaceXs: "12",
    // },{
    //   label: "Password",
    //   type: "password",
    //   placeholder: "Masukkan Password",
    //   spaceMd: "6",
    //   required: true,
    //   value: password,
    //   action: setPassword,
    // },{
    //   label: "Confirm Pasword",
    //   type: "password",
    //   placeholder: "Masukkan Confirm Pasword",
    //   spaceMd: "6",
    //   required: true,
    //   value: confirmPassword,
    //   action: setConfirmPassword,
    // },{
    //   label: "Update Password",
    //   type: "button_submit",
    //   spaceMd: "3",
    //   spaceXs: "3",
    //   action: doUpdate,
    // },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Detail Afiliator"}
        dataForm={dataForm}
        linkAccReview={"../accountReview"}
        id={id}
        pageFor={"detail"}
        email={email}
        onSubmit={doUpdate}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataAfiliator: state.afiliator,
  };
};

export default connect(
  storage
)(AfiliatorDetailPage)
